<template>
  <div class="embed-container">
    <iframe
      frameborder="0"
      allowfullscreen
      :src="url"
    />
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      url: '',
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      this.url = this.userData.urlReporte
      this.$store.commit('app/UPDATE_ISLOADING', false)
    })
  },
}

</script>

<style>
.embed-container {
    position: relative;
    padding-bottom: 50.25%;
    height: 0;
    overflow: hidden;
}
.embed-container iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
